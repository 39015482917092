import { Modal, ModalDialog, Typography } from "@mui/joy";
import React, { useState } from "react";
import { RiCloseLine } from "react-icons/ri";

const modalStyles = {
  p: 0,
  background: "none",
  border: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const Footer = () => {
  const [activeModal, setActiveModal] = useState<
    "privacy-policy" | "tnc" | "refundPolicy" | "gameRules" | ""
  >("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  function openModal(
    value: "privacy-policy" | "tnc" | "refundPolicy" | "gameRules" | ""
  ) {
    setActiveModal(value);
    setIsModalOpen(true);
  }

  function closeModal() {
    setActiveModal("");
    setIsModalOpen(false);
  }

  return (
    <div
      className="absolute bottom-2 text-[#FFFFFF] w-max left-[50%] -translate-x-[50%] p-2 md:p-4 rounded-lg"
      style={{
        background:
          "radial-gradient(50% 50% at 50% 50%, #DA1E1E 0%, #DC2525 54%, #AD1717 100%)",
      }}
    >
      <div className="flex justify-center items-center text-[10px]">
        <button
          className="border-r-2 pr-1 md:pr-2 border-[#FFFFFF]"
          onClick={() => openModal("privacy-policy")}
        >
          Privacy Policy
        </button>
        <button
          className="border-r-2 px-1 md:px-2 border-[#FFFFFF]"
          onClick={() => {
            openModal("refundPolicy");
          }}
        >
          Refund Policy
        </button>
        <button
          className="border-r-2 px-1 md:px-2 border-[#FFFFFF]"
          onClick={() => {
            openModal("gameRules");
          }}
        >
          Game Rules
        </button>
        <button
          className="pl-1 md:pl-2"
          onClick={() => {
            openModal("tnc");
          }}
        >
          Terms & Conditions
        </button>
      </div>

      <Modal open={isModalOpen} onClose={closeModal}>
        <ModalDialog sx={modalStyles}>
          <Typography>
            <div
              className={`bg-white border-2 border-red-600 rounded-2xl overflow-hidden flex flex-col gap-5 p-6 text-black m-auto max-w-[80vw] w-max`}
              style={{
                borderColor:
                  "linear-gradient(113.57deg, #830B0B 0%, #F90505 56.81%, #6D1212 103.3%)",
              }}
            >
              {activeModal == "privacy-policy" && (
                <div>
                  <div className="flex justify-end">
                    <RiCloseLine
                      className="text-2xl cursor-pointer"
                      onClick={() => closeModal()}
                    />
                  </div>
                  <div className="h-[80vh] md:h-[500px] overflow-auto text-xs md:text-base">
                    <Typography>
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration in
                      some form, by injected humour, or randomised words which
                      don t look even slightly believable. If you are going to
                      use a passage of Lorem Ipsum, you need to be sure there
                      isn t anything embarrassing hidden in the middle of text.
                      All the Lorem Ipsum generators on the Internet tend to
                      repeat predefined chunks as necessary, making this the
                      first true generator on the Internet. It uses a dictionary
                      of over 200 Latin words, combined with a handful of model
                      sentence structures, to generate Lorem Ipsum which looks
                      reasonable. The generated Lorem Ipsum is therefore always
                      free from repetition, injected humour, or non
                      characteristic words etc. <br />
                      <br /> Contrary to popular belief, Lorem Ipsum is not
                      simply random text. It has roots in a piece of classical
                      Latin literature from 45 BC, making it over 2000 years
                      old. Richard McClintock, a Latin professor at
                      Hampden-Sydney College in Virginia, looked up one of the
                      more obscure Latin words, consectetur, from a Lorem Ipsum
                      passage, and going through the cites of the word in
                      classical literature, discovered the undoubtable source.
                      Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de
                      Finibus Bonorum et Malorum (The Extremes of Good and Evil)
                      by Cicero, written in 45 BC. This book is a treatise on
                      the theory of ethics, very popular during the Renaissance.
                      The first line of Lorem Ipsum, "Lorem ipsum dolor sit
                      amet..", comes from a line in section 1.10.32.
                    </Typography>
                  </div>
                </div>
              )}
              {activeModal == "tnc" && (
                <div>
                  <div className="flex justify-end">
                    <RiCloseLine
                      className="text-2xl cursor-pointer"
                      onClick={() => closeModal()}
                    />
                  </div>
                  <Typography>
                    <div className="h-[80vh] md:h-[500px] overflow-auto">
                      <h1 className="text-base md:text-2xl font-semibold">
                        GAMATCH ENTERTAINMENT (OPC) PRIVATE LIMITED
                      </h1>
                      <p className="text-xs md:text-base">
                        Welcome to GAMATCH ENTERTAINMENT (OPC) PRIVATE LIMITED
                        (“GAMATCH ENTERTAINMENT”, “we”, “us”, or “our”). This
                        document outlines the terms and conditions (“Terms”)
                        governing your interaction with our company and its
                        services.
                      </p>

                      <h2 className="text-sm md:text-xl font-semibold">
                        1. Acceptance of Terms
                      </h2>
                      <p className="text-xs md:text-base">
                        By accessing or using GAMATCH ENTERTAINMENT’s services,
                        you agree to be bound by these Terms. If you do not
                        agree to these Terms, you may not access or use our
                        services.
                      </p>

                      <h2 className="text-sm md:text-xl font-semibold">2. Services</h2>
                      <p className="text-xs md:text-base">
                        GAMATCH ENTERTAINMENT provides entertainment services,
                        including but not limited to gaming platforms, software,
                        and related content.
                      </p>

                      <h2 className="text-sm md:text-xl font-semibold">
                        3. Intellectual Property
                      </h2>
                      <p className="text-xs md:text-base">
                        All content, including games, software, text, graphics,
                        logos, and trademarks, provided by GAMATCH ENTERTAINMENT
                        are the intellectual property of GAMATCH ENTERTAINMENT
                        or its licensors. Unauthorized use of this content is
                        prohibited.
                      </p>

                      <h2 className="text-sm md:text-xl font-semibold">
                        4. Account Creation
                      </h2>
                      <p className="text-xs md:text-base">
                        You may be required to create an account to access
                        certain features of GAMATCH ENTERTAINMENT’s services.
                        You are responsible for maintaining the confidentiality
                        of your account information and for all activities that
                        occur under your account.
                      </p>

                      <h2 className="text-sm md:text-xl font-semibold">
                        5. Withdrawal Policy for Card Game
                      </h2>
                      <p className="text-xs md:text-base">
                        <strong>Minimum Withdrawal:</strong> Users must withdraw
                        a minimum of ₹500.
                      </p>
                      <p>
                        <strong>Maximum Withdrawal:</strong> Users can withdraw
                        up to ₹50,000 in a single transaction.
                      </p>
                      <p>
                        <strong>TDS Deductions:</strong>
                      </p>
                      <ul>
                        <li>
                          No TDS will be deducted for withdrawal amounts below
                          ₹10,000.
                        </li>
                        <li>
                          For amounts above ₹10,000, a 30% TDS will be deducted,
                          as per government regulations.
                        </li>
                      </ul>
                      <p>
                        <strong>Processing Time:</strong> Withdrawal requests
                        will be processed within 24-48 hours.
                      </p>
                      <p></p>
                      <h2 className="text-sm md:text-xl font-semibold">6. Privacy</h2>
                      <p className="text-xs md:text-base">
                        GAMATCH ENTERTAINMENT respects your privacy and protects
                        your personal information in accordance with applicable
                        laws and regulations. Please refer to our Privacy Policy
                        for details on how we collect, use, and protect your
                        information.
                      </p>

                      <h2 className="text-sm md:text-xl font-semibold">
                        7. Limitation of Liability
                      </h2>
                      <p className="text-xs md:text-base">
                        GAMATCH ENTERTAINMENT shall not be liable for any
                        direct, indirect, incidental, special, consequential, or
                        punitive damages arising out of or related to your use
                        of our services.
                      </p>

                      <h2 className="text-sm md:text-xl font-semibold">8. Termination</h2>
                      <p className="text-xs md:text-base">
                        GAMATCH ENTERTAINMENT reserves the right to terminate or
                        suspend your access to our services at any time, without
                        prior notice or liability.
                      </p>

                      <h2 className="text-sm md:text-xl font-semibold">
                        9. Governing Law
                      </h2>
                      <p className="text-xs md:text-base">
                        These Terms shall be governed by and construed in
                        accordance with the laws of India. Any disputes arising
                        out of or related to these Terms shall be subject to the
                        exclusive jurisdiction of the courts in India.
                      </p>

                      <h2 className="text-sm md:text-xl font-semibold">
                        10. Changes to Terms
                      </h2>
                      <p className="text-xs md:text-base">
                        GAMATCH ENTERTAINMENT may update these Terms from time
                        to time. We will notify you of any changes by posting
                        the updated Terms on our website.
                      </p>

                      <h2 className="text-sm md:text-xl font-semibold">11. Contact Us</h2>
                      <p className="text-xs md:text-base">
                        If you have any questions or concerns about these Terms,
                        please contact us at the following:
                      </p>
                      <p className="text-xs md:text-base">
                        GAMATCH ENTERTAINMENT (OPC) PRIVATE LIMITED
                        <br />
                        11, ANAND VIHAR COLONY, Shree Sadan, Tekri Madri Link
                        Road
                        <br />
                        Kundan Shoe Maker, Udaipur (313001), Rajasthan.
                        <br />
                        Email: navismartgame@gmail.com
                      </p>

                      <p className="text-xs md:text-base">
                        By accessing or using GAMATCH ENTERTAINMENT’s services,
                        you acknowledge that you have read, understood, and
                        agree to be bound by these Terms.
                      </p>
                    </div>
                  </Typography>
                </div>
              )}
              {activeModal == "refundPolicy" && (
                <>
                  <div className="flex justify-end">
                    <RiCloseLine
                      className="text-2xl cursor-pointer"
                      onClick={() => closeModal()}
                    />
                  </div>
                  <div className="h-[80vh] md:h-[500px] overflow-auto">
                    <h1 className="text-lg md:text-2xl font-semibold">
                      Refund and Cancellation Policy
                    </h1>
                    <p className="text-xs md:text-base">
                      At GAMATCH ENTERTAINMENT (OPC) PRIVATE LIMITED (“GAMATCH
                      ENTERTAINMENT”, “we”, “us”, or “our”), we prioritize your
                      satisfaction with our services. While we strive to offer
                      seamless experiences, we understand that situations may
                      arise where you may need to cancel a purchase or seek a
                      refund. To ensure transparency and fairness, please
                      acquaint yourself with our refund and cancellation policy
                      outlined below:
                    </p>

                    <h2 className="text-base font-semibold">1. Refund Policy</h2>
                    <p className="text-xs md:text-base">
                      <strong>Digital Purchases:</strong> Once a purchase is
                      made through our platform, including virtual items,
                      currency, or in-game content, it is considered final and
                      non-refundable. This policy aligns with the nature of
                      digital content, where purchases cannot be reversed.
                    </p>
                    <p className="text-xs md:text-base">
                      <strong>Subscription Services:</strong> We don’t have
                      subscription services.
                    </p>

                    <h2 className="text-base font-semibold">2. Cancellation Policy</h2>
                    <p className="text-xs md:text-base">
                      <strong>Subscription Services:</strong> We don’t have
                      subscription services.
                    </p>
                    <p className="text-xs md:text-base">
                      <strong>Prepaid Services:</strong> For prepaid services or
                      products, such as game credits or membership packages,
                      cancellation may not be feasible once the service has been
                      activated or the product has been utilized. Please don’t
                      hesitate to contact us should you require assistance with
                      cancellation requests.
                    </p>

                    <h2 className="text-base font-semibold">3. Contact Us</h2>
                    <p className="text-xs md:text-base">
                      For any queries or concerns regarding our refund and
                      cancellation policy, please don’t hesitate to reach out to
                      us at:
                    </p>
                    <p className="text-xs md:text-base">
                      GAMATCH ENTERTAINMENT (OPC) PRIVATE LIMITED
                      <br />
                      11, ANAND VIHAR COLONY, Shree Sadan, Tekri Madri Link Road
                      <br />
                      Kundan Shoe Maker, Udaipur (313001), Rajasthan.
                      <br />
                      Email: navismartgame@gmail.com
                    </p>

                    <p className="text-xs md:text-base">
                      By utilizing the services provided by GAMATCH
                      ENTERTAINMENT, you acknowledge that you have read,
                      understood, and agreed to adhere to our refund and
                      cancellation policy. We reserve the right to modify or
                      update this policy at any time without prior notice,
                      ensuring continued alignment with our commitment to
                      customer satisfaction.
                    </p>
                  </div>
                </>
              )}
              {activeModal == "gameRules" && (
                <>
                  <div className="flex justify-end">
                    <RiCloseLine
                      className="text-2xl cursor-pointer"
                      onClick={() => closeModal()}
                    />
                  </div>
                  <div className="h-[80vh] md:h-[500px] overflow-auto">
                    <h1 className="text-lg md:text-2xl font-semibold">Game Rules</h1>
                    <ul className="text-xs md:text-base list-disc ml-4 mt-2">
                      <li>
                        This game is for entertainment, but you can win rewards
                        by playing the Navi Card game.
                      </li>
                      <li>
                        There is no need for any payment transaction to play a
                        single game.
                      </li>
                      <li>
                        The minimum gameplay amount is 50 INR, and the maximum
                        is 5000 INR.
                      </li>
                      <li>
                        The minimum deposit amount is 100 INR, and the maximum
                        deposit amount is 10000 INR.
                      </li>
                      <li>
                        Once the play amount is deducted from your side, it
                        cannot be canceled. You must play the selected game. If
                        you leave the game in between, the amount will not be
                        refunded to you.
                      </li>
                      <li>
                        You cannot claim from the company for any win or loss.
                        The company has full rights to declare the winner.
                      </li>
                      <li>
                        If you log out from the game due to an internet issue or
                        any other technical issue, the company is not liable to
                        pay you any amount.
                      </li>
                      <li>
                        For further information, call the customer care number
                        or use the WhatsApp chat option.
                      </li>
                    </ul>

                    <br />

                    <h2 className="text-lg md:text-2xl font-semibold">हिंदी में नियम</h2>
                    <ul className="text-xs md:text-base list-disc ml-4 mt-2">
                      <li>
                        यह गेम मनोरंजन के लिए है, लेकिन आप नवी कार्ड गेम खेलकर
                        पुरस्कार जीत सकते हैं।
                      </li>
                      <li>
                        एक गेम खेलने के लिए किसी भी प्रकार के भुगतान की आवश्यकता
                        नहीं है।
                      </li>
                      <li>न्यूनतम गेम प्ले राशि 0.50 है और अधिकतम 5000 है।</li>
                      <li>
                        न्यूनतम जमा राशि 100 है और अधिकतम जमा राशि 10000 है।
                      </li>
                      <li>अधिकतम दांव राशि 5000 है।</li>
                      <li>
                        एक बार जब आपके पक्ष से राशि कट जाती है, तो इसे रद्द नहीं
                        किया जा सकता। आपको चुना हुआ गेम खेलना ही होगा। यदि आप
                        बीच में गेम छोड़ते हैं, तो आपकी राशि वापस नहीं की जाएगी।
                      </li>
                      <li>
                        आप किसी भी जीत या हार के लिए कंपनी से दावा नहीं कर सकते।
                        विजेता घोषित करने के सभी अधिकार कंपनी के पास सुरक्षित
                        हैं।
                      </li>
                      <li>
                        यदि आप इंटरनेट या किसी अन्य तकनीकी समस्या के कारण गेम से
                        लॉगआउट हो जाते हैं, तो कंपनी आपको किसी भी राशि का भुगतान
                        करने के लिए उत्तरदायी नहीं होगी।
                      </li>
                      <li>
                        अधिक जानकारी के लिए, ग्राहक सेवा नंबर पर कॉल करें या
                        व्हाट्सएप चैट विकल्प का उपयोग करें।
                      </li>
                    </ul>
                  </div>
                </>
              )}
            </div>
          </Typography>
        </ModalDialog>
      </Modal>
    </div>
  );
};

export default Footer;
