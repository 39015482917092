import logo from "assets/images/Logo.png";
import SubmitButton from "components/buttons/SubmitButton";
import TextInput from "components/inputs/TextInput";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { appPath } from "routes/routes";
import apiService from "api/apiService";
import apiRoutes from "api/apiRoutes";
import userLocalStorage from "hooks/userLocalStorage";
import { useEffect, useState } from "react";
import CommonModal from "components/CommonModal";
import { RiCloseLine } from "react-icons/ri";

const schema = yup.object().shape({
  name: yup.string().required("Name is required."),
  email: yup.string().email().required("Email is required."),
  phone: yup
    .string()
    .required("Phone number is required.")
    .min(10, "Phone must be 10 digits.")
    .max(10, "Phone must be 10 digits."),
  countryCode: yup.number().required("Country Code is required."),
});

const Signup = () => {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      countryCode: 0,
    },
    resolver: yupResolver(schema),
  });
  const { setLocalStorage, getLocalStorage } = userLocalStorage();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const token = getLocalStorage("token");
    if (token) {
      navigate("/");
      return;
    }
  }, []);

  const onSubmit = async (data: any) => {
    setLoading(true);
    const formData: any = new FormData();
    formData.append("dialing_code", "+91");
    formData.append("phone", data?.phone);
    formData.append("name", data?.name);
    formData.append("email", data?.email);
    formData.append("game", "Card");
    await apiService
      .post(apiRoutes.AUTH.SIGNUP, formData)
      .then((response) => {
        setLocalStorage("user_data", JSON.stringify(response));
        navigate(appPath?.otp, {
          state: {
            data: data,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <CommonModal
        isModalOpen={openModal}
        setIsModalOpen={setOpenModal}
        content={
          <div>
            <div className="flex justify-end">
              <RiCloseLine
                className="text-2xl cursor-pointer"
                onClick={() => setOpenModal(false)}
              />
            </div>
            <div className="text-black">
              <h2>
                <strong className="ql-font-monospace">
                  Term and Conditions
                </strong>
              </h2>
              <h2>
                <br />
              </h2>
              <h2>
                <br />
              </h2>
              <ol>
                <li>The game is just for fun</li>
                <li>The game is for 18+ only.</li>
                <li>The game involves financial risk </li>
                <li>Play the game with your intelligence and wisdom</li>
                <li>
                  The company will not be responsible for any financial risk
                </li>
              </ol>
              <p>
                <br />
              </p>
              <p>
                <br />
              </p>
              <p>
                <br />
              </p>
              <ol>
                <li>गेम सिर्फ मनोरंजन के लिए है&nbsp;</li>
                <li>गेम सिर्फ १८+ ही खेले&nbsp;</li>
                <li>गेम मे वित्तीय झोकीम शामिल है&nbsp;</li>
                <li>गेम अपनी सूझ बुझ और समझदारी के साथ खेले&nbsp;</li>
                <li>
                  वित्तीय झोकीम होने पर कंपनी की कोई जिम्मेदारी नहीं होगी&nbsp;
                </li>
              </ol>
              <p>
                <br />
              </p>
              <p>
                <br />
              </p>
              <p>
                <br />
              </p>
            </div>
          </div>
        }
      />
      <div className="flex w-full h-screen">
        <div className="w-[50%] hidden md:block"></div>
        <div className="md:w-[50%] bg-white">
          <div className="flex flex-col justify-around items-center h-[80%] w-full pb-8">
            <div className="h-[15%]">
              <img
                src={logo}
                alt="logo"
                className="h-[100px] w-full object-contain"
              />
            </div>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col gap-5 justify-center h-[60%] w-[80%] flex-grow"
            >
              {/* <span className="text-white text-2xl">Sign up</span> */}

              {/* <Controller name="countryCode" control={control} defaultValue={0} render={({field}) => (
          <TextInput {...field} placeholder="Enter Country Code" error={errors?.countryCode?.message} />
        )} /> */}
              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <>
                    <TextInput
                      {...field}
                      type="text"
                      placeholder="Enter Name"
                      error={errors?.name?.message}
                    />
                  </>
                )}
              />

              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <>
                    <TextInput
                      {...field}
                      type="email"
                      placeholder="Enter Email"
                      error={errors?.email?.message}
                    />
                  </>
                )}
              />

              <Controller
                name="phone"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <>
                    <TextInput
                      {...field}
                      type="number"
                      placeholder="Enter Mobile Number"
                      error={errors?.phone?.message}
                    />
                  </>
                )}
              />

              <SubmitButton
                type="submit"
                text={loading ? "processing..." : "Login"}
              />
            </form>
            <div className="cursor-pointer" onClick={() => setOpenModal(true)}>
              <p className="text-2xl font-bold text-center">Disclaimer</p>
              <p className="text-sm text-center">T&C Apply</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
