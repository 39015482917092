import { yupResolver } from "@hookform/resolvers/yup";
import apiRoutes from "api/apiRoutes";
import apiService from "api/apiService";
import logo from "assets/images/Logo.png";
import clsx from "clsx";
import SubmitButton from "components/buttons/SubmitButton";
import TextInput from "components/inputs/TextInput";
import userLocalStorage from "hooks/userLocalStorage";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FaArrowLeft } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { appPath } from "routes/routes";
import * as yup from "yup";

const schema = yup.object().shape({
  otp: yup
    .string()
    .required("OTP is required")
    .min(6, "OTP must be 6 digits")
    .max(6, "OTP must be 6 digits"),
});

const OTPverification = () => {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      otp: "",
    },
    resolver: yupResolver(schema),
  });
  const { getLocalStorage, setLocalStorage } = userLocalStorage();
  const [loading, setLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const user = JSON.parse(getLocalStorage("user_data"));
  const location = useLocation();
  const [phone, setPhone] = useState("");
  const timerRef = useRef(60);
  const [timer, setTimer] = useState(60);
  const [signupData, setSignupData] = useState(null);

  const onSubmit = async (data: any) => {
    setLoading(true);
    //api call to validate OTP
    const _form = new FormData();
    _form.append("otp", data?.otp);
    _form.append("user_id", user?.user_id);
    await apiService
      .post(apiRoutes.AUTH.VERIFY_OTP, _form)
      .then((response) => {
        // if (response?.code == 200) {
        setLocalStorage("user_data", JSON.stringify(response?.user));
        setLocalStorage("token", response?.access_token);
        navigate(appPath?.home);
        // } else {
        //   throw response
        // }
      })
      .catch((error) => {
        console.log(error);
        setError("otp", {
          message: error?.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onResendOTP = async () => {
    setResendLoading(true);
    const formData: any = new FormData();
    formData.append("dialing_code", "+91");
    formData.append("phone", signupData?.phone);
    formData.append("name", signupData?.name);
    formData.append("email", signupData?.email);
    formData.append("game", "Card");
    await apiService
      .post(apiRoutes.AUTH.SIGNUP, formData)
      .then((response) => {
        setLocalStorage("user_data", JSON.stringify(response));
        timerRef.current = 60;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setResendLoading(false);
      });
  };

  useEffect(() => {
    const token = getLocalStorage("token");
    if (token) {
      navigate("/");
      return;
    }
    if (location?.state?.data?.phone) {
      setPhone(location?.state?.data?.phone);
      setSignupData(location?.state?.data);
    }

    const intervalId = setInterval(() => {
      if (timerRef.current > 0) {
        timerRef.current -= 1; // Decrement the time in the ref
        setTimer(timerRef.current);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="flex w-full h-screen">
      <div className="w-[50%] hidden md:block"></div>
      <div className="md:w-[50%] bg-white">
        <div className="flex flex-col justify-start items-center h-full w-full pb-8">
          <div className="h-[15%]">
            <img
              src={logo}
              alt="logo"
              className="h-[100px] w-full object-contain"
            />
          </div>
          <div className="flex flex-col gap-5 mt-10 w-[80%] text-black">
            <span className="text-2xl font-semibold">Enter OTP to Signup</span>
            <span className="">OTP has been sent to +91{phone}</span>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col gap-4"
            >
              <Controller
                name="otp"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextInput
                    {...field}
                    type="number"
                    placeholder="Enter OTP"
                    error={errors?.otp?.message}
                  />
                )}
              />
              <SubmitButton
                text={loading ? "Processing..." : "Verify OTP"}
                type="submit"
              />
            </form>
            <div className="grid gap-4">
              <p className="text-xl text-center">or</p>
              <p className="text-center">
                <span
                  onClick={() => {
                    if (timer == 0 && !resendLoading) {
                      onResendOTP();
                    }
                  }}
                  className={clsx(
                    timer == 0 ? "cursor-pointer underline text-blue-500" : ""
                  )}
                >
                  Resend OTP
                </span>{" "}
                {timer != 0 && (
                  <span>in 00:{timer < 10 ? `0${timer}` : timer}</span>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OTPverification;
