import apiRoutes from "api/apiRoutes";
import apiService from "api/apiService";
import React, { useState } from "react";
import { FaArrowLeft, FaRupeeSign } from "react-icons/fa";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

interface Props {
  handleCloseModal: () => void;
}

const WithdrawModal = ({ handleCloseModal }: Props) => {
  const [amount, setAmount] = useState<string>("");
  const [showAlert, setSHowAlert] = useState<boolean>();
  const [showAlertMsg, setSHowAlertMsg] = useState<string>();
  const [loading, setLoading] = useState(false);
  const { walletBalance } = useSelector((state: any) => state?.game);

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    setLoading(true);
    if (!amount) {
      setSHowAlert(true);
      setSHowAlertMsg("Amount is required");
      setLoading(false);
      return;
    }
    const _form = new FormData();
    _form.append("amount", amount);
    apiService
      .post(apiRoutes.USER.WITHDRAW_AMOUNT, _form)
      .then((response) => {
        console.log(response);
        if (response?.code == 200) {
          setAmount("");
          handleCloseModal();
        } else {
          throw response;
        }
      })
      .catch((error) => {
        console.log(error);
        setSHowAlertMsg(error?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleAmountChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    // Allow empty string or valid number
    if (value === "" || !isNaN(Number(value))) {
      setAmount(value);
    }
  }

  return (
    <div className="flex flex-col gap-5">
      <div className="flex items-center gap-2">
        <FaArrowLeft onClick={handleCloseModal} />
        <span className="text-xl font-semibold">Withdraw Money</span>
      </div>

      <div className="flex flex-col w-full items-center">
        <div className="flex items-center gap-2">
          <FaRupeeSign />
          <span className="text-4xl font-semibold">{walletBalance}</span>
        </div>
        <span>Total Amount</span>
      </div>

      <div className="flex flex-col gap-3">
        <form className="flex flex-col" onSubmit={handleSubmit}>
          <label className="flex items-center">
            Enter Amount (<FaRupeeSign />)
          </label>
          <span className="border-b-[1px] border-b-gray-500 flex items-center">
            <FaRupeeSign className="text-sm" />
            <input
              type="text"
              value={amount}
              onChange={handleAmountChange}
              className="focus:outline-none text-lg font-semibold"
              placeholder="0"
            />
          </span>
          {showAlert && (
            <span className="text-sm text-red-600">{showAlertMsg}</span>
          )}
          <button className="py-2 px-5 bg-red-300 text-black mt-5 rounded-full m-auto w-fit tracking-wide">
            {loading ? "processing..." : "withdraw"}
          </button>

          <span className="text-xs text-black font-semibold mt-4">
            Note: Minimum Withdrawl amount must be 500.
          </span>
        </form>
      </div>
    </div>
  );
};

export default WithdrawModal;
