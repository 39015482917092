import { teamSize } from "types/enum";
import PlayerCard from "components/PlayerCard";
import { useNavigate } from "react-router-dom";
import { appPath } from "routes/routes";
import singlePlayer from "assets/images/singleplayer.png";
import multiPlayer from "assets/images/multiplayer.png";
import { setGamePlayers, setPlayers } from "store/gameReducer";
import { useDispatch } from "react-redux";
import logo from "assets/images/Logo.png";

const HomePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function startGame(value: string, count: number) {
    dispatch(setGamePlayers(value));
    dispatch(setPlayers(count));
    if (value == teamSize.single) {
      navigate(`/game/play/1`, {
        state: {
          game_id: 1,
          game_type: value,
        },
      });
    } else {
      navigate(appPath.selectPrize);
    }
  }

  return (
    <div className="flex flex-col h-[calc(100vh-100px)] mx-4 pt-14">
      <div className="flex items-center justify-center my-8">
        <img
          src={logo}
          height={"auto"}
          width={"20%"}
          className="cursor-pointer w-[150px] md:w-[20%]"
          onClick={() => navigate(appPath.home)}
        />
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 md:justify-evenly items-center w-full gap-4 md:gap-10 overflow-auto max-w-[100vw]">
        <div onClick={() => startGame(teamSize.single, 1)}>
          <PlayerCard type={"single"} image={singlePlayer} />
        </div>
        <div onClick={() => startGame(teamSize.multi, 2)}>
          <PlayerCard type={"2"} image={multiPlayer} />
        </div>
        <div onClick={() => startGame(teamSize.multi, 3)}>
          <PlayerCard type={"3"} image={multiPlayer} />
        </div>
        <div onClick={() => startGame(teamSize.multi, 4)}>
          <PlayerCard type={"4"} image={multiPlayer} />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
