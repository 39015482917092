import Routing from "routes/Routing";
import { useLocation } from "react-router-dom";
import Navbar from "components/Navbar";
import appConfig from "config/appConfig";
import Footer from "components/Footer";
import "./App.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { PusherClient } from "utils/pusher/PusherClient";
import Music from "components/music/Music";

function App() {
  const { pathname } = useLocation();
  const showNav = !appConfig.nonHeaderComps.includes(pathname);
  const showFooter = !appConfig.nonFooterComps.includes(
    pathname.replace(/\/[0-9]+/g, "")
  );

  useEffect(() => {
    PusherClient.connection.bind("connected", () => {
      console.log("Pusher is connected");
    });
  }, []);

  return (
    <div className="bg-splash app">
      <Music />
      {showNav && <Navbar />}
      <Routing />
      {showFooter && <Footer />}
      <ToastContainer autoClose={3000} />
    </div>
  );
}

export default App;
