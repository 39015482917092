import apiRoutes from "api/apiRoutes";
import apiService from "api/apiService";
import axios from "axios";
import appConfig from "config/appConfig";
import dayjs from "dayjs";
import userLocalStorage from "hooks/userLocalStorage";
import React, { useEffect, useRef, useState } from "react";
import { FaMinus, FaPlus } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";

interface Props {
  handleCloseModal: () => void;
}

const mockData = [
  {
    date: "02/10/2024",
    data: [
      {
        won: true,
        players: "single",
        amount: 100,
      },
      {
        won: false,
        players: "single",
        amount: 100,
      },
      {
        won: false,
        players: "multi",
        amount: 1000,
      },
      {
        won: true,
        players: "single",
        amount: 100,
      },
    ],
  },
  {
    date: "01/10/2024",
    data: [
      {
        won: true,
        players: "multi",
        amount: 1000,
      },
    ],
  },
];

const GameHistory = ({ handleCloseModal }: Props) => {
  const [walletData, setWalletData] = useState([]);
  const { getLocalStorage } = userLocalStorage();
  const walletTransactionRef = useRef();
  const [page, setPage] = useState(1);
  const [prevPage, setPrevPage] = useState(0);
  const [lastList, setLastList] = useState(false);
  const [loading, setLoading] = useState(false);

  const getWalletTransaction = () => {
    setLoading(true);
    const param = `?page=${page}`;
    axios
      .get(appConfig.apiUrl.dev + apiRoutes.USER.WALLET_TRANSACTION + param, {
        headers: {
          Authorization: `Bearer ${getLocalStorage("token")}`,
        },
      })
      .then((response) => {
        if (response?.status == 200) {
          if (!response?.data?.data.length) {
            setLastList(true);
            return;
          }
          setPrevPage(page);
          setWalletData([...walletData, ...response?.data?.data]);
        }
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!lastList && prevPage !== page) {
      getWalletTransaction();
    }
  }, [page, lastList, prevPage, walletData]);

  const onScroll = () => {
    if (walletTransactionRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        walletTransactionRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        setPage(page + 1);
      }
    }
  };

  return (
    <div
      onScroll={onScroll}
      ref={walletTransactionRef}
      className="overflow-auto h-[500px] w-[90vw] max-w-full wt-scroll mt-4"
    >
      <div className="relative flex flex-col gap-5">
        {walletData.map((gameDetail, index) => {
          return (
            <div key={index} className="flex flex-col">
              <span>
                {walletData?.[index - 1]?.created_at?.slice(0, 10) !=
                gameDetail?.created_at?.slice(0, 10)
                  ? dayjs(gameDetail?.created_at).format("DD/MM/YYYY")
                  : ""}
              </span>
              {/* <div className="flex flex-col"> */}
              {/* {obj.data.map((gameDetail, gameDetailIndex) => ( */}
              <div className="p-2 border-b-[1px] border-gray-400 flex justify-between items-center">
                <div className="capitalize font-semibold grid">
                  {gameDetail?.type}
                  <span className="font-normal text-sm">{gameDetail?.meta?.description}</span>
                </div>
                <div
                  className={
                    gameDetail?.type == "deposit"
                      ? "text-green-500 flex items-center gap-1"
                      : "text-red-500 flex items-center gap-1"
                  }
                >
                  {/* <span className="text-sm">
                  {gameDetail?.type == "deposit" ? <FaPlus /> : <FaMinus />}
                </span> */}
                  <span className="font-semibold text-lg">
                    {gameDetail?.amount}
                  </span>
                </div>
              </div>
              {/* ))} */}
              {/* </div> */}
            </div>
          );
        })}
        {loading && (
          <div className="w-[30px] h-[30px] border-4 border-red-500 rounded-full mx-auto border-r-transparent animate-spin "></div>
        )}
      </div>
      <button
        className="text-xl absolute top-2 right-3 focus:outline-none"
        onClick={handleCloseModal}
      >
        <FaXmark color="black" />
      </button>
    </div>
  );
};

export default GameHistory;
