const apiRoutes = {
  AUTH: {
    SIGNUP: "user/create/otp",
    VERIFY_OTP: "user/verify-phone",
    REGISTER: "/register",
  },
  USER: {
    DETAILS: "/user-details",
    BYID: (id: string) => `/user/${id}`,
    UPDATE_PROFILE: "user/update-profile",
    WALLET_BALANCE: "user/wallet-balance",
    WALLET_TRANSACTION: "user/wallet-transaction",
    WITHDRAW_AMOUNT: "user/payout-request"
  },
  GAME: {
    CARD_LIST: "user/card/cards",
    GAME_LIST: "user/card/game-list",
    JOIN_GAME: "user/card/join-game",
    PLAY_GAME: "user/card/play-game",
    GAME_UPDATE: "user/card/play-game-update",
    GAME_LEFT: "user/card/card-game-left"
  }
};

export default apiRoutes;
