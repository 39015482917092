import { yupResolver } from "@hookform/resolvers/yup";
import apiRoutes from "api/apiRoutes";
import apiService from "api/apiService";
import userLocalStorage from "hooks/userLocalStorage";
import React, { ChangeEvent, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FaArrowLeft, FaRupeeSign } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { appPath } from "routes/routes";
import * as yup from "yup";
import TextInput from "./inputs/TextInput";
import { Avatar, TextField } from "@mui/material";
import { MdEdit } from "react-icons/md";
import Rupee from '../assets/images/rupee.png'

const userData = {
  name: "Maun Snow",
  email: "maunsnow@gmail.com",
  countryCode: 91,
  contact: 9999990000,
  walletAmount: 1000,
};

const schema = yup.object().shape({
  name: yup.string().required("Name is required."),
  email: yup.string().email().required("Email is required."),
  profile_photo: yup.mixed().nullable(),
});

interface Props {
  handleCloseModal: () => void;
}

const ProfileBox = ({ handleCloseModal }: Props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { clearLocalStorage, getLocalStorage, setLocalStorage } =
    userLocalStorage();
  const user = JSON.parse(getLocalStorage("user_data"));
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      name: user?.name,
      email: user?.email,
      profile_photo: "",
      // aadhar_number: "",
    },
    resolver: yupResolver(schema),
  });
  const profileInputRef = useRef<any>(null);

  const handleSelectProfile = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files[0]) {
      setValue("profile_photo", event.target.files[0]);
    }
  };

  function handleLogout() {
    //logout
    clearLocalStorage();
    handleCloseModal();
    navigate(appPath.signup);
  }

  const onSubmit = async (data: any) => {
    setLoading(true);
    const formData: any = new FormData();
    formData.append("name", data?.name);
    formData.append("email", data?.email);
    formData.append("profile_photo", watch("profile_photo"));
    await apiService
      .post(apiRoutes.USER.UPDATE_PROFILE, formData)
      .then((response) => {
        setLocalStorage("user_data", JSON.stringify(response));
        setIsEdit(false);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className="flex justify-between items-center w-[90vw] max-w-full text-sm font-semibold">
        <div className="flex items-center gap-2">
          <FaArrowLeft onClick={handleCloseModal} className="cursor-pointer" />
          <span className="text-black">Profile Details</span>
        </div>
        <button className="text-red-600" onClick={() => setIsEdit(!isEdit)}>
          {isEdit ? "Cancel" : "Edit"}
        </button>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6">
        <div className="border rounded-xl bg-[#F9F9F9] w-full flex flex-col p-4 pt-10 mt-4 gap-6 relative">
          <div className="absolute -top-8 left-[50%] -translate-x-[50%]">
            <div className="relative">
              {user?.profile_photo ? (
                <Avatar
                  sx={{ width: 56, height: 56 }}
                  src={
                    user?.profile_photo_url ||
                    (watch("profile_photo") &&
                      URL.createObjectURL(
                        watch("profile_photo") as File | undefined
                      ))
                  }
                  alt="profile"
                />
              ) : (
                <Avatar sx={{ width: 56, height: 56 }}>
                  {user?.name?.slice(0, 1)}
                </Avatar>
              )}
              {isEdit && (
                <div
                  className="absolute -right-2 bottom-0 bg-black text-white p-1 rounded-full cursor-pointer"
                  onClick={() => profileInputRef.current.click()}
                >
                  <MdEdit />
                  <input
                    ref={profileInputRef}
                    type="file"
                    onChange={handleSelectProfile}
                    name="profile_photo"
                    hidden
                  />
                </div>
              )}
            </div>
          </div>
          <div className="flex gap-8 items-center">
            <span className="font-semibold">Name</span>
            {!isEdit ? (
              <span>{user?.name}</span>
            ) : (
              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <>
                    <TextField
                      {...field}
                      type="text"
                      placeholder="Enter Name"
                      className="w-full"
                      size="small"
                      error={Boolean(errors?.name?.message)}
                      helperText={errors?.name?.message}
                    />
                  </>
                )}
              />
            )}
          </div>

          <div className="flex gap-8 items-center">
            <span className="font-semibold">Email</span>
            {!isEdit ? (
              <span className="text-ellipsis overflow-hidden">
                {user?.email || "-"}
              </span>
            ) : (
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <>
                    <TextField
                      {...field}
                      type="email"
                      placeholder="Enter Email"
                      className="w-full"
                      size="small"
                      error={Boolean(errors?.email?.message)}
                      helperText={errors?.email?.message}
                    />
                  </>
                )}
              />
            )}
          </div>

          <div className="flex gap-8 items-center">
            <span className="font-semibold">Contact No.</span>
            <span>{user?.phone}</span>
          </div>
        </div>
        <div className="border rounded-xl bg-[#F9F9F9] w-full flex justify-between items-center p-2">
          <span className="font-semibold">
            <img src={Rupee} alt="money icon" width={50} height={50} />
          </span>
          <span className="flex gap-1 items-center">
            <FaRupeeSign /> {user?.wallet_balance}
          </span>
        </div>
        {isEdit ? (
          <div className="w-full flex justify-center items-center">
            <button
              className="bg-[#FFC9C8] rounded-full py-2 px-4"
              type="submit"
            >
              {loading ? "Updating..." : "Update"}
            </button>
          </div>
        ) : (
          <div className="w-full flex justify-center items-center">
            <button
              className="bg-[#FFC9C8] rounded-full py-2 px-4"
              onClick={handleLogout}
            >
              Logout
            </button>
          </div>
        )}
      </form>
    </>
  );
};

export default ProfileBox;
